import React, {Component} from 'react';
import './main.css'

import twitterLogo from './media/Twitter-Logo-Blue.svg';
import discordLogo from './media/Discord-Logo-Color.svg';
import facebookLogo from './media/facebook_logo.svg';
import instagramLogo from './media/instagram-logo.svg';
import youTubeLogo from './media/yt_logo_rgb_dark.svg';
import emailIcon from './media/email_icon.svg';
import {TopMenu} from "./TopMenu";
import {BottomMenu} from "./BottomMenu";
import steamLogoWhite from "./media/SteamLogoWhite.png"

import actionWheelAG from "./media/AnimatedGIFs/ActionWheel.gif"
import brainAG from "./media/AnimatedGIFs/Brain.gif"
import frontDeskAG from "./media/AnimatedGIFs/FrontDesk.gif"
import ghostType2AG from "./media/AnimatedGIFs/GhostType2.gif"
import selectorsAG from "./media/AnimatedGIFs/Selectors.gif"
import zoomerAG from "./media/AnimatedGIFs/Zoomer.gif"


export default class MainMenu extends Component {
    render() {
        return <>
            <TopMenu/>

            <div className={'main-menu-primary-display'}>
                <div className={'main-menu-primary-display-row-1'}>
                    <iframe width="560" height="315"
                            src="https://www.youtube.com/embed/videoseries?si=gKLo2LQL3Vqaf7y-&amp;list=PLs0tUk9CgiTak8RlQTE9u_AtCQsFmEAnk&amp;autoplay=1&amp;mute=1&amp;controls=0&amp;loop=1&amp;"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    <div>
                        <iframe src="https://store.steampowered.com/widget/2915980/" frameBorder="0" width="646"
                                height="190"
                                title="Haunted Space Hotel on Steam"
                        ></iframe>
                    </div>

                </div>
            </div>

            <hr/>


            <div className={'main-menu-primary-display-row-3'}>

                <img src={zoomerAG} alt={'Play As AI'}/>
                <h1>In Haunted Space Hotel: Vacancy you play as an Artificial Sentience tasked with building a
                    Profitable Hotel in time of great prosperity and harmony in the Galaxy.</h1>

                <img src={selectorsAG} alt={'Select'}/>
                <h2>Distinctive Choices</h2>
                <p>Choose your origin carefully from 6 truly unique Species, each with a different strengths and
                    weaknesses, affording awesome replayability. Hire and Fire Captains from each Faction who may
                    help or hinder your intentions as you navigate through the many distinctive systems across the
                    Galaxy.</p>

                <img src={actionWheelAG} alt={'Wheel'}/>
                <h2>Plan for Maximum Profit</h2>
                <p>Use Robots and Research to Renovate: Amenities, Beds, and Lodging on your massive Hotel
                    spaceship. The distinctive Haunted Space Hotel Action Hex makes it highly improbable you can do
                    everything you want in a single Shift, so plan your Days ahead.</p>


                <img src={brainAG} alt={'Brain'}/>
                <h2>Grow Your Artificial Brain</h2>
                <p>The massive tech tree has over 2000 points of interest. The neural network generates beneficial
                    attributes that further increase your capabilities. Do you spend your Snohe on your brain or
                    invest it in the future of your Hotel?</p>

                <img src={frontDeskAG} alt={'Reception'}/>
                <h2>Attend to Your Guests</h2>
                <p>Most guests exude Miysst which you extract to create batteries which accelerate all of your
                    abilities. Satisfied Guests may offer gifts, and pay in Snohe, the most treasured and profitable
                    currency in the Galaxy. Unsatisfied guests can get rather disgruntled, and can do things like
                    damage your hotel, or raid your Vault.</p>


                <img src={ghostType2AG} alt={'Ghost'}/>
                <h2>Deal with the Unexpected</h2>
                <p>Ghosts hitchhike in Guest ships and just show up during travel using Leigh Lanes. Use Ghost
                    Handlers to clear buildings, and track down the pesky poltergeist yourself throughout the Hotel.
                    There are many Ancient Mysteries surrounding these entities, but you are strictly forbidden to
                    communicate with them, just don’t do it.</p>

                <h2>The more Brains the Better</h2>
                <p>The game was built with Co-Op in mind, so playing with friends has only upside. You have a
                    Commander and a Hotel, both of which are unique to you. Friends’ Commanders can drop in at any
                    time and help manage your hotel. Player rewards are equally shared, so when you go back to your
                    own hotel you keep your loot! Haunted Space Hotel: Vacancy has enough going on that a group of
                    friends can play together and never want for something to do. Friends can help each other with
                    any task.</p>

                <h2>Built for Early Access</h2>
                <p>Haunted Space Hotel: Vacancy was designed with the community in mind. Player feedback with help
                    space a number of Major Updates that introduce new feature, characters, destinations, and more
                    as the game gets closer to completion.</p>


            </div>
            <hr/>
            <div className={'main-menu-primary-display-row-5'}>
                <a className="social-logo"
                   href="https://twitter.com/haunted_space" title="Twitter">
                    <img src={twitterLogo}
                         alt="twitter"/>
                </a>
                <a className="social-logo" href="https://discord.gg/jtsM2PgukX"
                   title="Discord">
                    <img src={discordLogo}
                         alt="discord"/>
                </a>
                <a className="social-logo"
                   href="https://www.facebook.com/hauntedspacehotel"
                   title="Facebook">
                    <img src={facebookLogo}
                         alt="facebook"/>
                </a>
                <a className="social-logo"
                   href="https://www.instagram.com/hauntedspacehotel/"
                   title="Instagram">
                    <img src={instagramLogo}
                         alt="facebook"/>
                </a>
                <a className="social-logo"
                   href="https://www.youtube.com/@HauntedSpaceHotel"
                   title="YouTube">
                    <img src={youTubeLogo}
                         alt="youtube"/>
                </a>
                <a className="social-logo"
                   href="mailto:play@hauntedspacehotel.com" title="email">
                    <img src={emailIcon} alt="email"/>
                </a>
            </div>
            <BottomMenu/>
        </>
    }
}
