import React, {Component} from 'react';
import './main.css'


export class BottomMenu extends Component {
    render() {
        return <>
            <hr/>
            <div className={'main-menu-primary-display-row-7'}>
                <div>&copy;2024 Fossa Tracks, Inc.</div>
                <div>Haunted Space Hotel &#8482; is a registered trademark of Fossa Tracks, Inc.</div>
                <div>Terms of Service</div>
            </div>
            <hr/>
            <div className={'main-menu-bottom-spacer'} />
        </>
    }
}
