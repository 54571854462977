import React, {Component} from "react";
import {BottomMenu} from "./BottomMenu";
import {TopMenu} from "./TopMenu";

export default class About extends Component {
    render() {
        return <div style={{margin: '1rem'}}>
            <TopMenu/>
            <h1>About</h1>
            <p>Haunted Space Hotel &#8482; is a registered trademark.</p>
            <p>Haunted Space Hotel &#8482; Studios is a DBA of Fossa Tracks, Inc. a California Corporation.</p>
            <p>We are currently based out of San Diego, CA.</p>

            <p>Or reach out to us at <a href={'mailto://info@hauntedpsacehotel.com/'}>info@hauntedpsacehotel.com</a></p>
            <BottomMenu />
        </div>
    }
}
