import {Route, Routes} from "react-router-dom";
import About from "./components/About";
import MainMenu from "./components/Main";

import PatchNotes from "./components/PatchNotes";

function App() {
    return (<div>
            <Routes>
                <Route path={'about'}
                       element={<About app={this}/>}
                />
                <Route path={'patchnotes'}
                       element={<PatchNotes app={this}/>}
                />
                <Route path="*" element={<>
                    <MainMenu app={this}/>
                </>
                }/>
            </Routes>
        </div>
    );
}

export default App;
