import React, {Component} from 'react';
import './main.css'
import {Link} from "react-router-dom";
import hshVLogo from './media/LogoTransparent_184x103.png';
import discordLogo from "./media/Discord-Logo-Color.svg";


export class TopMenu extends Component {
    render() {
        return <>

            <div className={'main-menu-top-bar'}>
                <Link className={'main-menu-top-bar-item'} to={'/'}>
                    <img
                        className={'main-menu-top-bar-logo'}
                        src={hshVLogo}
                        alt={'logo'}
                    />
                </Link>
                <div className={'main-menu-top-bar-item'}>
                <a className="social-logo" href="https://discord.gg/jtsM2PgukX"
                   title="Discord">
                    <img src={discordLogo}
                         alt="discord"
                         width="50px" height="50px"
                    />
                </a>
                </div>
                <Link className={'main-menu-top-bar-item'} to={'/patchnotes'}>Patch-Notes</Link>
                <Link className={'main-menu-top-bar-item'} to={'/about'}>Studios</Link>
            </div>
            <hr/>
        </>
    }
}
