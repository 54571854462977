import React, {Component} from "react";
import {BottomMenu} from "./BottomMenu";
import {TopMenu} from "./TopMenu";

export default class About extends Component {
    render() {
        return <div style={{margin: '1rem'}}>
            <TopMenu/>
            <h1>Patch Notes</h1>

            <h3>Vacancy 0.0.1.6</h3>
            <ul>
                <li>Quest Log Custom Frame</li>
                <li>Skip Turn Button</li>
                <li>No more flying in Ley Space</li>
                <li>In Game Help images</li>
                <li>Patch notes moved to hauntedspacehotel.com/patchnotes</li>
                <li>Added Steam widget to hauntedspacehotel.com</li>
            </ul>

            <p className="c1 c21 c8"><span className="c19"></span></p><p className="c1 c8 c21"><span
                className="c19"></span></p>
            <table className="c13">
                <tr className="c11">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/29/2024 18:37:41</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.1.5 - Quests+</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Prepare for a meaty update:</span>
                    </p><p className="c1"><span className="c2">* New Quest System! It only has a couple of quests in it but there it is. (Made from scratch).</span>
                    </p><p className="c1"><span
                        className="c2">* New custom Soundtrack by Nate BeuMaher!!! So Awesome.</span></p><p
                        className="c1"><span className="c2">* By popular demand, brought back the UI Time Keeper for ya, you&#39;re welcome.</span>
                    </p><p className="c1"><span
                        className="c2">* Xenorama has an all new sign and is fully functional.</span></p><p
                        className="c1 c8"><span className="c2"></span></p><p className="c1"><span
                        className="c2">Fixes:</span></p><p className="c1"><span className="c2">* River Flow</span></p><p
                        className="c1"><span className="c2">* Lodging deactivation / reactivation</span></p><p
                        className="c1"><span className="c2">* Miysst drops would remain</span></p><p className="c1">
                        <span className="c2">* Pickup storage was going to the miniature hotel on the bridge (so hilarious).</span>
                    </p><p className="c1"><span className="c2">* Research upgrade was sticking</span></p><p
                        className="c1"><span
                        className="c2">* Reno pickups were being allowed in the Research station</span></p><p
                        className="c1"><span className="c2">* Captain Quest was pointing to the wrong place.</span></p>
                        <p className="c1"><span className="c2">* Starting System was wrong (it is based on your Commander)</span>
                        </p><p className="c1"><span className="c2">* Boost was breaking</span></p><p className="c1">
                            <span className="c2">* Reno training could be broken by a witty player, Added a action points check to assure unbreakability.</span>
                        </p><p className="c1"><span className="c2">* In Training an extra battery could be used to break the economy training, removed it.</span>
                        </p><p className="c1"><span className="c2">* Xenorama wouldn&#39;t stop talking, it resets if everyone leaves the room now.</span>
                        </p><p className="c1"><span className="c2">* Captain 3d character didn&#39;t switch faction when choosing a new hotel.</span>
                        </p><p className="c1"><span className="c2">* Limbo could chatter on if left early, it now terminates if u bail.</span>
                        </p><p className="c1"><span
                            className="c2">* Mini-Hotels had objectives and storage, removed.</span></p></td>
                </tr>
                <tr className="c22">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/23/2024 15:08:30</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.1.4</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">0.0.1.2 - Added Gameplay Video and Intro using Limbo.</span>
                    </p><p className="c1"><span className="c2">0.0.1.3 - Tech Tree Replication, Ninja Effects, Boost/Dash, Fixed Gates.</span>
                    </p><p className="c1"><span className="c2">0.0.1.4 -</span></p><p className="c1"><span
                        className="c2">* New HSH Studios Logo and Pregame Video</span></p><p className="c1"><span
                        className="c2">* Xenorama now is fully functional!!! More to come but please enjoy!</span></p><p
                        className="c1"><span className="c2">* Steam Store Approval</span></p><p className="c1"><span
                        className="c2">* Interjections - Yes, No - Now TNames</span></p><p className="c1"><span
                        className="c2">* Publish HSH Studios in an actual newspaper.</span></p><p className="c1"><span
                        className="c2">* Add Seizure Warning.</span></p><p className="c1"><span className="c2">* Add Pre-Release Tag to main header.</span>
                    </p><p className="c1"><span className="c2">* In-Game Help! You asked for it you got it! Press H for Help. Still needs images. Suggest some stills!</span>
                    </p><p className="c1"><span className="c2">* Advertisement Training was missing the battery affordance arrow.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/14/2024 18:56:52</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.1.1 - Steam+</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Additional Steam Gamepad controls.</span>
                    </p><p className="c1"><span
                        className="c2">* Pause when controller changes or opening Steam overlay.</span></p><p
                        className="c1"><span className="c2">* Release of new gameplay trailer.</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/14/2024 13:17:17</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.1.0 - Pre-Release Head</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">This represents the release we are submitting to Steam for release validation.</span>
                    </p></td>
                </tr>
                <tr className="c15">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/13/2024 14:31:06</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.51</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Very first time FTUE.</span>
                    </p><p className="c1"><span className="c2">* Fix: Replication: Vault, Battery Vault, Brain, Log, Fly, Teleport.</span>
                    </p><p className="c1"><span className="c2">* Move Steam connection into hotel map load for dedicated server (interesting).</span>
                    </p><p className="c1"><span className="c2">* Known replication issues: Action Charges and Stations are quirky, Eyeballs don&#39;t reduce in size, Guests show Miysst even when they don&#39;t have any. All will be fixed shortly.</span>
                    </p><p className="c1"><span className="c2">* Progress made on real world puppet, that could be very fun indeed.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/9/2024 17:20:55</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.50</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Player Id method changed to be more multiplayer friendly.</span>
                    </p><p className="c1"><span className="c2">* Touched most things due to this change.</span></p><p
                        className="c1"><span className="c2">* Local player save is now based on your steam id.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/7/2024 18:13:18</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.49</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Okay after much argument with the Steam Networking client, I think we are G2G. Please do give multiplayer a test! I believe most other things are back to working order, but only you can know!!!! Play and let me know what I missed. Over the next few days I&#39;m going to be shooting in game videos in preparation for our upcoming early-access release. Now is a great time to test for us.</span>
                    </p></td>
                </tr>
                <tr className="c15">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">7/24/2024 14:40:44</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.47</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Start menu is now just start.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">* Advertisement dock display was quirky, patched.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">* Multiplayer is always available now (testing phase).</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">7/23/2024 17:13:29</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.45</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Voids are a bit easier to spot now!</span>
                    </p><p className="c1"><span className="c2">* Pickup put containers have meshes.</span></p><p
                        className="c1"><span className="c2">* Switching commanders is Easy and Free now.</span></p><p
                        className="c1"><span className="c2">* Multiplayer is seamless now on all Levels.</span></p></td>
                </tr>
                <tr className="c15">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">7/18/2024 11:59:44</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.44</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Main Menu now is Single-Player &amp; Multiplayer.</span>
                    </p><p className="c1"><span className="c2">* Training now uses its own save so you can start it at anytime without damaging your existing game.</span>
                    </p><p className="c1"><span className="c2">* Training now lives in the Xenorama.</span></p><p
                        className="c1"><span className="c2">* All systems now have a Day/Night cycle (and still need to be updated with new layouts).</span>
                    </p><p className="c1"><span className="c2">* Entirely new Bridge including brand new Navigation system. Way awesome.</span>
                    </p></td>
                </tr>
                <tr className="c20">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">7/7/2024 12:58:23</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.43</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Added set of present tense verbs for player directives. (All translatable of course)</span>
                    </p><p className="c1"><span className="c2">* Made a new set of T-Shirts</span></p><p className="c1">
                        <span className="c2">* Took down Manager.</span></p><p className="c1"><span className="c2">* Completely rebuilt the Advertisement workstation, you&#39;re welcome.</span>
                    </p><p className="c1"><span className="c2">* By request the order of Training has been changed, as well as the Advertisement training touched.</span>
                    </p><p className="c1"><span className="c2">* All training steps now require a click or an interaction.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">7/2/2024 11:50:45</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.42</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Exit Portal from Innerspace</span>
                    </p><p className="c1"><span className="c2">* Tech tree major nodes were not Generic Buttons.</span>
                    </p><p className="c1"><span className="c2">* Move Generic Button click tracer to the Gameplayer Controller.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">7/1/2024 18:15:04</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.41</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Innerspace Update with all new choice sequence.</span>
                    </p><p className="c1"><span className="c2">* Improvements to Training, and removal of &quot;off track&quot; event possibilities.</span>
                    </p></td>
                </tr>
                <tr className="c17">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">6/26/2024 18:58:17</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.40</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Steam RichPresence indicating menu or in game statuses.</span>
                    </p><p className="c1"><span className="c2">* Teleporter Cooldown (sorry)</span></p><p
                        className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">* Intelligence = Miysst Harvest Speed</span>
                    </p><p className="c1"><span className="c2">* Charisma = Ghost Void Removal Speed</span></p><p
                        className="c1"><span className="c2">* Dexterity = Teleporter Cooldown Speed</span></p><p
                        className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">Fix: Bots go to their assignment when reloading.</span>
                    </p><p className="c1"><span className="c2">Fix: Uninstall weakness and random</span></p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">6/25/2024 18:50:04</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.39</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Training - Anyone should be able to play now.</span>
                    </p><p className="c1"><span className="c2">* Localizable Dialogs.</span></p><p className="c1"><span
                        className="c2">* More controller support.</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">6/13/2024 17:56:46</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 0.0.0.38</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Guest Rifts! Guests now drop everything you need.... but it doesn&#39;t last, pick it up and use it or store it in a matching Action Room ASAP! Also lots of fixes and QoL fixes. Let&#39;s play!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">6/4/2024 15:44:09</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.0.37 06042024</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Ghost Voids first pass: 3d camera on 2d surface, niagara with moving endpoint for hero effect. Sound and attenuation for void. Save locations for reload. Not replication tested but should work.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/31/2024 11:36:59</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">0.0.0.36 05312024</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Still arguing pretty strongly with Multiplayer replication. This patch only serves to provide me more log information about what is and is not replicating.</span>
                    </p></td>
                </tr>
                <tr className="c16">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/30/2024 17:50:53</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.0.35 05302024</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* FIX: Exit when not host.</span>
                    </p><p className="c1"><span
                        className="c2">* FIX: Multiplayer when directly to hotel from menu.</span></p><p className="c1">
                        <span
                            className="c2">* Steam text when &quot;In Hotel&quot; should be accurate now, we will see.</span>
                    </p><p className="c1"><span className="c2">* Wheel and Floor of ops replication and black when unactivated.</span>
                    </p><p className="c1"><span className="c2">* FIX: Dock card doubles.</span></p><p className="c1">
                        <span className="c2">* Exposed guest score and details when mousing over a guest card.</span>
                    </p><p className="c1"><span className="c2">* Maybe Fixed: Action icon partial material loss when replicated</span>
                    </p><p className="c1"><span className="c2">* Clock is now black when unactivated and replicates properly.</span>
                    </p></td>
                </tr>
                <tr className="c16">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/30/2024 13:02:46</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.0.34 05302024</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Keyboard and Controller prompts (as requested by Steam)</span>
                    </p><p className="c1"><span className="c2">* FIX: Vacancy Not filling properly</span></p><p
                        className="c1"><span className="c2">* Batteries may now be created from Snohe in the Vault (it takes 4 intervals atm)</span>
                    </p><p className="c1"><span className="c2">* Actions may now be created from Batteries in the appropriate Action Room (thus you want core rooms)</span>
                    </p><p className="c1"><span className="c2">* Reset Character button added. Reset takes u back to Innerspace. Does NOT cast Reset Hotel.</span>
                    </p><p className="c1"><span className="c2">* Kiosk Multiplayer Replication.</span></p><p
                        className="c1"><span className="c2">* FIX: Staff and Ghost handler flow for create, juice, flush. Note: Kiosks degrade as intended. Juice does not degrade Kiosks.</span>
                    </p><p className="c1"><span className="c2">* First pass at Advertisement Workstation multiplayer replication, need to see it active.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/28/2024 9:28:01</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.0.33 05282024</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* Fix Vacancy from repeating itself.</span>
                    </p><p className="c1"><span className="c2">* Full Controller Support (hopefully).</span></p><p
                        className="c1"><span className="c2">* Music reintegration.</span></p></td>
                </tr>
                <tr className="c17">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/24/2024 19:04:06</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.0.32 05242024</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">* FREE-284 Fix teleport while large</span>
                    </p><p className="c1"><span className="c2">* FREE-253 New cinematics for steam</span></p><p
                        className="c1"><span className="c2">* FREE-289 Rapid selection between Commanders and Hotels, clean confirmation</span>
                    </p><p className="c1"><span className="c2">* FREE-290 Do not jump over the action wheel</span></p><p
                        className="c1"><span className="c2">* FREE-286 Expose Logs (Esc menu)</span></p><p
                        className="c1"><span className="c2">* FREE-263 Multiplayer fix Workstation Action displays... All non energized actions are now black. Much more to do to get multiplayer to replicate cleanly.</span>
                    </p><p className="c1"><span className="c2">* FREE-291 End of Day was looping. Captain now always chooses before movement if you dont&#39; choose.</span>
                    </p><p className="c1"><span className="c2">* FREE-292 Scipraxian Variables</span></p><p
                        className="c1"><span className="c2">* FREE-287 Verb icon updates SVO Sentences updated.</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/22/2024 21:48:51</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 0.0.0.31 05222024</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Very long day. So here&#39;s a patch which will blow your mind. Seriously it&#39;s insane, go look.</span>
                    </p><p className="c1"><span className="c2">Live on steam for playtesters: https://store.steampowered.com/news/app/2976220/view/4172100635480491376</span>
                    </p><p className="c1"><span className="c2">Here&#39;s the highlights for everyone else:</span></p><p
                        className="c1"><span className="c2">Update 0.0.0.31 05222024</span></p><p className="c1"><span
                        className="c2">Massive Innerspace Update</span></p><p className="c1"><span className="c2">New Dialogs and Waypoints</span>
                    </p><p className="c1"><span className="c2">Fix: There&#39;s no way onto the Hotel without picking and acknowledging first. TY Play testers.</span>
                    </p></td>
                </tr>
                <tr className="c23">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/17/2024 18:04:16</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 05172024 Build 0.0.0.30</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Major upgrade to the economy.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1 c8"><span
                        className="c2"></span></p><p className="c1"><span className="c2">FREE-265 Store Media</span></p>
                        <p className="c1"><span className="c2">FREE-276 FIX Weakness Room Actions</span></p><p
                            className="c1"><span className="c2">FREE-270 Remove Multiplayer Gateway</span></p><p
                            className="c1"><span className="c2">FREE-275 FIX No stars on Move</span></p><p
                            className="c1"><span className="c2">FREE-277 FIX Remove lame fish, put good fish.</span></p>
                        <p className="c1"><span className="c2">FREE-254 New Website Graphics!</span></p><p
                            className="c1"><span className="c2">FREE-256 Start In Hotel rather than Innerspace (if appropriate)</span>
                        </p><p className="c1"><span className="c2">FREE-260 Gateway back to Innerspace</span></p><p
                            className="c1"><span className="c2">FREE-262 New Nouns and Verbs: Neuron, Quest, Satisfied, Unsatisfied, Paid, Charged, Gifted, Purchased</span>
                        </p><p className="c1"><span className="c2">FREE-279 Experience Points and Neurons!</span></p><p
                            className="c1"><span className="c2">FREE-230 Subject Verb Object for Snohe Accounting (way nicer)</span>
                        </p><p className="c1"><span className="c2">FREE-255 Tech Tree purchase Neurons.</span></p><p
                            className="c1"><span className="c2">FREE-212 Snohe Vaults and Battery Storage</span></p><p
                            className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">Go Get EM!</span>
                        </p></td>
                </tr>
                <tr className="c14">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">5/10/2024 10:32:23</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 05102024 Build 0.0.0.29</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Greetings Hotel Managers!</span>
                    </p><p className="c1"><span className="c2">Today I am pleased to announce that our PRIVATE Playtest is now available on Steam!!!!</span>
                    </p><p className="c1"><span className="c2">Hit me up here on Discourse or on Discord and I&#39;ll get you into the test. Don&#39;t ask unless you are willing to put in some hours please.</span>
                    </p><p className="c1"><span className="c2">Playtesting involves me giving you a FREE KEY on Steam just for the playtest. Once the store is up you</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">To get to this point took a bunch of builds and tweaks, here&#39;s a partial list:</span>
                    </p><p className="c1"><span className="c2">FIX: Start with camera fully zoomed.</span></p><p
                        className="c1"><span className="c2">FREE-252 Multiplayer now functional. Disabled TextureStreaming FREE-23 Now includes Options menu as well. Removed render focus. FIX: Small Hotel in Captain Selector. Emissive Scipraxian text.</span>
                    </p><p className="c1"><span className="c2">FREE-252 Removes the old &quot;Open World&quot; map, trying to aliviate as many errors as I can. Still not sure why the hotel level seems tos tore in open world.</span>
                    </p><p className="c1"><span className="c2">FREE-252 Clearing Out whatever I can to get as many warnings out as possible. Advertisment kiosk is dead, debris room is dead.</span>
                    </p><p className="c1"><span className="c2">FREE-252 Using the Testgame APPID instead. Clearing a warning on the dock controller (legacy queue).</span>
                    </p><p className="c1"><span className="c2">FIX: Action wheel was going the wrong way, no clue why. Turned off debug mode on NPC Objectives.</span>
                    </p><p className="c1"><span className="c2">FREE-252 Touch materials on ghost room, observatory, and reception.</span>
                    </p><p className="c1"><span className="c2">FIX: Redirectors for openworld map</span></p><p
                        className="c1"><span className="c2">FREE-73 Adding graphics to the splash.</span></p><p
                        className="c1"><span className="c2">FREE-73 Clearing more log warnings.</span></p><p
                        className="c1"><span className="c2">FREE-252 Retrostars Statue</span></p><p className="c1"><span
                        className="c2">FREE-73 Trying to clean all warnings if possible. Giving a &quot;build target&quot; actually did nothing.</span>
                    </p><p className="c1"><span className="c2">FREE-252 New Observatory</span></p><p className="c1">
                        <span className="c2">FREE-252 New Bar</span></p><p className="c1"><span className="c2">FREE-73 Steam Integration and Levels for Screenshots.</span>
                    </p><p className="c1"><span className="c2">FREE-251 Generic Room</span></p><p className="c1"><span
                        className="c2">FREE-248, FREE-249 Ghost Type 1 has local wander again. Still need to consolidate ghosts to a generic base.</span>
                    </p><p className="c1"><span className="c2">HOTFIX: Swapped CTRL and ALT for cursor and fly.HOTFIX: Flow button wouldn&#39;t take a mouse click. Used a better pattern, other buttons should follow this method.</span>
                    </p></td>
                </tr>
                <tr className="c15">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">4/26/2024 19:06:23</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 04262024 Build 0.0.0.24</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Brand new reception! Check it out!</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">Characters now &quot;use&quot; their NPC Objectives, fun animations added. All GenericNPCs now (finally) use the same animation blueprint, I was able to get everything to be dynamic, very super happy about that.</span>
                    </p><p className="c1"><span className="c2">FREE-245 Dock Cards are now much easier to read, lookat, click, and be happy with.</span>
                    </p><p className="c1"><span className="c2">FREE-247 Added new reception, new hallways, reintroduced missing system assets (planets), Fish Tank!</span>
                    </p></td>
                </tr>
                <tr className="c18">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">4/23/2024 17:39:12</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 04232024 Build 0.0.0.23</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">A nice fresh new build is up for you.</span>
                    </p><p className="c1"><span className="c2">A ton happened. The build completely failed, took me forever to figure out that it wasn&#39;t my fault. Silly Unreal. So after being really unhappy, I moved the project into GIT finally, so now I have full takebackcies, I should have done it awhile ago but the LFS cost was worrisome. Fortunately Bitbucket will give me a gig for free, so that&#39;s enough for now.</span>
                    </p><p className="c1"><span className="c2">After the meltdown:</span></p><p className="c1"><span
                        className="c2">Added utility for array shift.</span></p><p className="c1"><span className="c2">Added Vault, Captain, Teleport, Dock, and other TNames for sentence Icons.</span>
                    </p><p className="c1"><span className="c2">Updated tech tree with new icon vocabulary and fixed async loader for cooked builds.</span>
                    </p><p className="c1"><span className="c2">By popular demand added more Ghost Type 2.</span></p><p
                        className="c1"><span
                        className="c2">Updated techtree loader, and added logger for purchases.</span></p><p
                        className="c1"><span className="c2">Super cool new Retrostars in the lobby, really fun.</span>
                    </p><p className="c1"><span className="c2">Faction Bedrooms!!!!! All in enjoy!</span></p><p
                        className="c1"><span className="c2">New NPCObjectives! I replaced Unreal&#39;s buggy SmartObjects with my own NPCObjective objects, and surprise surprise, mine works first time with none of Unreal&#39;s lame crashes.</span>
                    </p><p className="c1"><span className="c2">NPCObjectives for Reception (for pilots only), Embarkation, and generic objects throughout the ship.</span>
                    </p><p className="c1"><span className="c2">Remember that Orbits only fill during shift 1, vacancy kicks in beginning of shift 2 only, and you can now spend Advertisement actions to draw orbits more times.... do you spend it on campaigns or instant gratification?</span>
                    </p><p className="c1"><span className="c2">We have a ton of new stuff headed in over the next few weeks, help us out and play test.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">4/6/2024 14:09:43</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 04062024 Build 0.0.0.20</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Major Overhaul of Advertisement. Bookings are immediate just like in HSH Manager. Chances are explicitly based on Charisma and System Location. Secret dock teleportation system, will likely hook to the Techtree which still has a few broken nodes, lmk if you find one. This is important: Orbits fill during Shift 1, Orbits Empty Shift 2, Orbits remain empty until Shift 1. So Booking has some very specific timeframes now, heads up. Thanks for testing.</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">4/1/2024 15:03:33</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 04012024 Build 0.0.0.18</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I removed FluidNinja completely because it has an inherent build problem. Took me forever to isolate it, but then the issue didn&#39;t go away. I had started to use the Unread 3D Text plugin from Epic Games so I expected it to work, HA no, won&#39;t even build! so lame. So I probably didn&#39;t really need to remove Ninja.... I&#39;ll add it back in after we implement the new building changes coming soon.</span>
                    </p><p className="c1"><span className="c2">TechTree now loads async which eliminates most of the load time for the game (I hate load screens).</span>
                    </p><p className="c1"><span className="c2">2 keys to remember: R takes our hero to reception or the gateway to reception. B goes to the bridge or brain (techtree). Quality of life stuff which you will likely unlock with the tech tree, but they are free for now.</span>
                    </p><p className="c1"><span className="c2">More coming, keep playing!</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/29/2024 19:06:40</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 03292024 Build 0.0.0.17</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Got the tools out of the mix, got navigation to actually start you in the right place, disconnected teleport in InnerSpace... then reconnected &quot;R&quot; for reception in InnerSpace to take us more quickly to the hotel upon restarting the game. Action Hex starts rotated as per the last save. Cleared a bunch of warnings on the build. Lots more to do, today&#39;s build is more stable and robust. Again I tried to reduce the size of the build, and that went very poorly, I&#39;ll try again soon. Sits at about 2.5 gigs at the moment.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/28/2024 14:06:05</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 03282024 Build 0.0.0.16</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Really sorry about the build size btw... I promise to trim it down soon.</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/28/2024 14:05:21</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy 03282024 Build 0.0.0.16</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Here&#39;s another build for you.</span>
                    </p><p className="c1"><span className="c2">Always move the end of shift 3, choose your destination at the captain&#39;s wheel before then. Planets, stars, nebulas, planetary rings, improved Guest behavior a bit.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">Started framework for Steam and Epic integration.</span>
                    </p></td>
                </tr>
                <tr className="c16">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/11/2024 18:33:00</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 03112024</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I have a build for you.</span>
                    </p><p className="c1"><span className="c2">Workstation Activity Stations work again, and may be purchased from Guests again.</span>
                    </p><p className="c1"><span
                        className="c2">Miysst, Batteries and Such now are a separate &quot;Character&quot; save. Hotel save has everything else. Your Character follows you in multiplayer while your Hotel is something others join.</span>
                    </p><p className="c1"><span className="c2">The multiplayer upgrade tweaked with most things, so everything needs to be touch tested.</span>
                    </p><p className="c1"><span className="c2">Go have fun, if you break it let me know :)</span></p><p
                        className="c1 c8"><span className="c2"></span></p><p className="c1"><span
                        className="c2">Note:</span></p><p className="c1"><span className="c2">Multiplayer does indeed run locally if you run two (or more) copies of the game. I need to work on getting the replication to be &quot;lighter&quot; as it seems the game is a bit bogged down (only when loading) when there are 4 players. I&#39;ll keep hacking at it, but I&#39;m pausing on MP for a bit to get other systems in place sooner rather than later. Feel free to ask questions.</span>
                    </p></td>
                </tr>
                <tr className="c11">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/7/2024 10:27:30</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Vacancy 03072024</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;m not ready to release it to you yet, but very soon. I wanted to get all the latest additions noted, and then switch back to Jira for a big push to have a playable multiplayer version this month. So here&#39;s the list of what we have added since the last patch:</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span
                        className="c2">Features:</span></p><p className="c1"><span className="c2">* Multiplayer.</span>
                    </p><p className="c1"><span className="c2">* Shift Sound (ding, ding ding, ding ding ding) you must be near a clock to hear it.</span>
                    </p><p className="c1"><span className="c2">* Main menu perspective.</span></p><p className="c1">
                        <span className="c2">* New Shadow Commander Avatars.</span></p><p className="c1"><span
                        className="c2">* Local gameplayer save for Avatar (your character follows you).</span></p><p
                        className="c1"><span className="c2">* Commander flight toggle (fly or walk), speed will eventually depend on tech tree.</span>
                    </p><p className="c1"><span className="c2">* Vacancy Toggle Button (on bridge near captain).</span>
                    </p><p className="c1"><span className="c2">* Sentence logger (subject verb object).</span></p><p
                        className="c1"><span className="c2">* Stats using the new logger.</span></p><p className="c1">
                        <span className="c2">* All guest ship models w/ animations (common Animation Blueprint).</span>
                    </p><p className="c1"><span className="c2">* 2000 point tech tree.</span></p><p className="c1"><span
                        className="c2">* New Innerspace which runs locally allowing you to use the Commander chooser, Hotel/Captain chooser, visit the TechTree and Xenorama.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span
                        className="c2">Fixes:</span></p><p className="c1"><span
                        className="c2">* Ghost hunter batteries.</span></p><p className="c1"><span className="c2">* Guest ship navigation is now boring but much lighter, it will do for now.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span
                        className="c2">Todo:</span></p><p className="c1"><span className="c2">This is a very long list atm. Top priority is to flesh out a build I can give you to play.</span>
                    </p></td>
                </tr>
                <tr className="c10">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/31/2024 19:45:37</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy Build 0.0.0.13 Now Available</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Bridge Update</span></p><p className="c1"><span className="c2">Ghost Type 2 starts in a random built room now, good luck.</span>
                    </p><p className="c1"><span className="c2">Shift click is now emissive.</span></p><p className="c1">
                        <span className="c2">Guests occasionally have kiosk followers relative to their type, Kiosks current cost 1 battery to become an Activity Station of the matching type. So now you can have 6 of each type of Activity Station.</span>
                    </p><p className="c1"><span className="c2">Fixed guests being stuck in the docks.</span></p><p
                        className="c1"><span className="c2">New Seed Character update (looking way better).</span></p><p
                        className="c1"><span
                        className="c2">PLEASE give it a try and LMK if you have any concerns.</span></p></td>
                </tr>
                <tr className="c20">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/27/2024 19:33:09</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy Build 0.0.0.12 Now Available</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fixed portals for Arena and Casino.</span>
                    </p><p className="c1"><span
                        className="c2">Added Starting point and smart objects to all rooms.</span></p><p className="c1">
                        <span className="c2">Rebuilt &quot;HexGrid&quot; material for hex w/ normals etc.</span></p><p
                        className="c1"><span className="c2">Mini-Ghost is now unique and leaves a trail!!!</span></p><p
                        className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">More to come!</span>
                    </p></td>
                </tr>
                <tr className="c22">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/27/2024 10:42:01</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy Update 01/26/2024</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Wow there was a lot yesterday:</span>
                    </p><p className="c1"><span className="c2">* Color Library in place (Removed non-canonical color references)</span>
                    </p><p className="c1"><span className="c2">* Removed UE5 Lumen use, increase framerate by 15% or more.</span>
                    </p><p className="c1"><span className="c2">* Logo update, 3d Logo in main menu.</span></p><p
                        className="c1"><span className="c2">* New Stats module for keeping track of player activities (i.e. Number of times Staff has been activated), should be the cornerstone of the questing system.</span>
                    </p><p className="c1"><span className="c2">* Altered the hotel 3d grid menu to use the original hotel layout with angled docks, this was altered for the 2d browser version, we are simply going back to the original canonical/triangular boards.</span>
                    </p><p className="c1"><span className="c2">* Added Mini-Ghosts worth 10 Miysst! Go find em!</span>
                    </p><p className="c1"><span className="c2">* Dread are finally Clothed! They also have awesome movement animations now.</span>
                    </p><p className="c1"><span className="c2">* Shadow now is more unique and changes colors per faction.</span>
                    </p><p className="c1"><span className="c2">* Fixed bug where staff upgrades didn&#39;t show on the kiosk.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">I hope to have a new versioned build out for play testing today!</span>
                    </p></td>
                </tr>
                <tr className="c15">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/23/2024 19:36:14</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy Build 0.0.0.11 available</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">New Reception and hallways.</span>
                    </p><p className="c1"><span className="c2">Smart Objects are back! Guests have some things to do now.</span>
                    </p><p className="c1"><span className="c2">Trying out a &quot;within range&quot; automatic Miysst collection method, I think it&#39;s better, but not perfect yet. Thoughts?</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">So much more coming soon!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/22/2024 22:44:03</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">New round of testing please 01222024</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Done, Thank you very much for testing.</span>
                    </p></td>
                </tr>
                <tr className="c12">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/22/2024 12:12:14</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">New round of testing please 01222024</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Nice new build up for you.</span>
                    </p><p className="c1"><span className="c2">We are looking to confirm that the core game loop is functional.</span>
                    </p><p className="c1"><span className="c2">There are no training videos yet, hopefully by end of week I will be at that step.</span>
                    </p><p className="c1"><span className="c2">If you can&#39;t find the Google Drive link, please let me know and I&#39;ll send it to you again.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/24/2023 11:23:20</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Vacancy ready for Play Testing (UE 5.3)</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Yesterday I posted the first version of Vacancy I&#39;m confident you can play test. Today I moved Unreal Engine versions! So look for the update in the folder.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/22/2023 15:59:49</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">New Version up for play testing</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;m not going to post a link here (yet) but if you would like to help, LMK and I&#39;ll send u a build.</span>
                    </p><p className="c1"><span className="c2">Latest build is focusing on the gameplay loop.</span></p>
                    </td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/3/2023 10:51:03</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fix HSHA-544: Fix game options</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Game options were not saving.</span>
                    </p><p className="c1"><span className="c2">All fixed.</span></p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/1/2023 13:19:29</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Quick Update 08012023</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Wanted to check in. We are working extremely diligently on the new game. I finally stopped and broke it all down into tickets so I can: A be Agile (dug), and B so I can report in progress here a bit better.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">We have 3D models for all the core rooms and most amenities. We have 5 of the 6 captain characters rigged. Lots of game components are working now, so we are starting to have solid conversations again about game theory and flow. It&#39;s a critical time, full of creativity and inspiration. Thanks for being on this journey with us.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">6/22/2023 10:24:31</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">06222023 Cert Update</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">SSL Cert was out of date.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">4/6/2023 22:20:52</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Locked In</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;m really confident in the current build. I&#39;ve switched my attention to Unreal.... be afraid! :)</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">4/3/2023 15:08:57</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">040320231143 Groups, targets, and ease of Play</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;ve caused the group manager to be a bit less chatterful. I&#39;ve caused many things to be &quot;more live.&quot; Delete game now moves all players out to the menu. Thanks for testing!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">4/1/2023 22:33:38</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Updates! Mostly manual controls</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;ve recently moved to Signals, and a few of the manual controls are still touchy. I&#39;ve gotten most of them, but I&#39;m still play testing. The observers are now very very live, so that&#39;s fun again. I&#39;ll finish up the signal updates over the next day or so, then clean and prep for minting before a move to Unreal. Playtest everything!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/25/2023 15:08:15</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Let&#39;s rebuild it for signals</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-541: Fairly massive overhaul removing GameController from all references. Now using Signals when there is an event like &quot;highlight.&quot; Added AI understanding of Vanguards. And Many other &quot;fixes&quot; to the maximum velocity flow. About to have it fight itself on device.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/22/2023 11:49:09</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Full Speed Ahead. 032220230846</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Lord Bean is now 100% front end driven. I have removed all the player choice backend calculations. 4X (0 wait) play is now fully functional Frontend AI vs Backend AI. Next up is front end AI vs front end AI all from the same origin.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/20/2023 17:55:42</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Little behind on comments 032020231451</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">The latest build is 032020231451.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">So everything is now running without limiters. I am actively working to remove the &quot;player choice&quot; calculations by the back end. This should speed things up fairly remarkably as well as cut down on unremarkable chatter. The goal with my current direction is to remove the backend logic which can be handled by the front end. This is all in preparate to move the entire engine into javascript so people can play without a server. Lord Bean is currently dominating Fossa pretty well.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/9/2023 18:46:30</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Lord Bean now activates random action rooms. Speed up to 1 second</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Random action rooms are a big deal, Fossa did it now lord bean. Lord Bean is also obsessed with sending Special Guests. I have auto play 3x working, 1 second. Now to see if I can get it even faster. The delay is basically to get the back end a moment, will try and overcome that need.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/9/2023 15:28:27</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-533 The river now works as the creators intended, even under full simulation</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">The tickers were overwhelming the browser, so after rebuilding the river, the problem persisted. I have truncated the tickers, and all seems right with the world again.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/7/2023 20:57:52</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Empowered Captains</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Your captain will now run everything on your ship. They still need an update for special guests, that&#39;s what I&#39;m doing right now. I pushed the latest update. Enjoy! More coming soon.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">3/4/2023 23:13:32</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Oh boy... Introducing Lord Bean</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I have moved a chunk of the AI into Javascript, and improved upon it. Your Captain now gives you advice constantly. Belay, Concur, and even Auto is now an option. I&#39;m still working on Select, so no full full auto yet, but it&#39;s pretty auto man....</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/28/2023 22:20:24</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Big push today. Getting the new AI helper online in prep for Captains!</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-532: Creating route for helper/artificial players. Includes lodging action. Added utilities for game player information (more to come). Added a metric ton of constants. Added AI controls/component for display and select. FIX: remove action click from the swiper, just do the thing (ghost needs this). FIX: package router transcript error. FIX: Cause game player data to be properly updated when the game and/or players are updated. FIX: Action grid generator nulls.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/21/2023 12:27:50</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fix: Card grid expansion</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">There was a mini-build error which only occured under weird conditions. I rebuilt using a full component and the problem just stopped. So compiler error, fixed.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/20/2023 20:31:55</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Card Grid and Pools</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Card Grid is built entirely on the front end now.</span>
                    </p><p className="c1"><span className="c2">Pools grid is also entirely front end.</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/20/2023 16:29:45</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Moving Day! (figuratively). All card calcs are now officially on the front end</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">It was taking up to 5 ms per calculations... yikes cause I was doing it on every single gameplayer/game push, yikes. All removed, front end tests pass. I&#39;m working on end-to-end integration, should be good. More manual testing is happening as we speak.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/18/2023 19:06:45</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fossa Upgrade: Research/Renovation</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;m trying a new routine which plays toward aristocrats... we will see....</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/17/2023 13:36:38</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fossa Upgrade: Advertisement</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Fossa Upgrade! He&#39;s much better at Advertisement now. You&#39;re welcome?</span>
                    </p><p className="c1"><span className="c2">There&#39;s room for further improvement, he doesn&#39;t look 3 moves ahead like we do, but it is way way better than it was.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/16/2023 15:49:58</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Big push, Expansion and Game Configuration chooser. Main menu videos</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Videos for main menu and Dread cards.</span>
                    </p><p className="c1"><span className="c2">Game configuration and expansion choices added to casual play menu.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/14/2023 15:16:55</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Exclaim random results</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Added some text to show you what you rolled &quot;in your face&quot;</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/13/2023 20:11:22</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fate Brings you... and free guest highlighting</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">All fate rolls now show text fading.</span>
                    </p><p className="c1"><span className="c2">Free guests are highlighted at the appropriate time (force update on turnStatus).</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/13/2023 16:41:24</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">UX Update 021320231237</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-512: Fate, board rearrange, wait for display, staff choice pause.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/10/2023 23:37:37</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">021020231934 Animated Hex Cards</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Let&#39;s push out some more awesome cards by @HonoredVanguard</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/10/2023 20:16:58</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">021020231614 Core Rules Change! Burning guests may give advertisement</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">If you have a matching lodging and amenity for a guest who will burn, and is available (not buried) They will offer free advertisement.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/9/2023 16:40:39</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">The Danger Statement</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;ve moved the point calculations to the front end (as well as the backend).</span>
                    </p><p className="c1"><span className="c2">Added actual tests on the front end proving efficacy and accuracy.</span>
                    </p><p className="c1"><span className="c2">This is the first in a list of items I want to move to the front end in anticipation of a backendless system.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/9/2023 13:19:42</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">020920230917 Observer update, reduced animation times</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Here we go again. Now observer looks the same as single player. and i&#39;ve reduced the animation times, as well as cause the river to &quot;start from here&quot; anytime it reaches the burn phase.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">2/8/2023 13:02:02</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Big big patch, all new UI</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">So it took me awhile. But here&#39;s an entirely rebuilt UI, with an all animated river now.</span>
                    </p><p className="c1"><span className="c2">Here&#39;s a before and after for ya.</span></p><p
                        className="c1"><span className="c2">![HSH Before and After 2023-02-08 084725|534x500](upload://8S9VSETiXx6UqQWogkb7hyafij3.jpeg)</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/20/2023 15:27:31</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Ghost wave 1 over 9 and Start with 2 ghosts</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">After great debate, we are modifying the starting configuration. You get 1 ghost each round once you are over 9. You start with 2 ghosts in the same docks as the debris.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/20/2023 13:55:48</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Random == 1</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I have some other big changes cooking on the front end, but here&#39;s an update, random rooms now only cost 1 staff. That should be interesting.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">1/11/2023 17:12:46</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">011120223 Tradelane animations</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Game Player tradelanes are now animated. Check them out and let me know.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">12/27/2022 12:36:25</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">122720220831 Activation method</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Finally. I took a bit an fixed the robot activate deactivate. Works flawlessly now!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">12/23/2022 13:18:12</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Upgrade core Python packages+</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">So I was using an older Redis from the experiencemaster.com build. I attempted to migrate to Digital Ocean, but .... they require TLS encryption, which django-channels doesn&#39;t explicitly support. I&#39;d have to really dig into it, so I ended up giving up, and causing my existing redis instance to upgrade to 6... Now all of that is happy again, and the core is running on the very latest builds of the core libraries. Latest Django still isn&#39;t explicitly available with native EC2, again I can attempt, but I didn&#39;t want to deal with anything that wasn&#39;t &quot;as intended.&quot; Lots of other things have been touched on the way by.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">12/16/2022 14:37:45</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Pools to Card grid view</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I moved the pools from the main view into the card grid view (button on the top bar). We just don&#39;t care enough to see it all the time.... at the moment....</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">12/16/2022 13:16:07</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Inventory and animations</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Inventory is now accessed via the toggle button to the right of the action hex and when renovating/researching obviously. Activated Robots now all animate properly. Empty rooms don&#39;t show locked (pirates).</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">12/14/2022 19:42:09</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Renovation Highlighter</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Much better now. Really needed it.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">12/14/2022 17:04:50</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Card Grid Scrolling</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I moved the card grid to inside the scrollable window.... better I think.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">12/14/2022 14:18:35</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push: Lets see those scores!</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Lots of updates, but the most remarkable is the score overlay. We have added numeric indicators over cards as points are put in place. Should help with understanding.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">12/6/2022 19:25:00</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push! Invite from friends menu</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Let&#39;s make it easy to invite an online friend.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">11/30/2022 12:38:34</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Let&#39;s see what they have!</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Competitor view now shows player action grids and storages.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">11/17/2022 13:46:10</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Update send and receive special guests</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Quick update, should be much more descriptive now, even updated the glyphic log.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">11/16/2022 13:47:02</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Juice and Anti-Juice overhaul</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Now called clocking-in and clocking-out. For the action, it will always be juicing to me! HA. Now much more intuitive interface.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">11/14/2022 19:47:00</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Robots activate!</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">New 2D animations for activating rooms, actual robots! 111420221545</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">11/14/2022 15:55:10</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">New lock button and new dock affordances</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">111420221149 Trying to make it as easy as possible for everyone. Clearer button for LOCK and Much nicer dock animations (2d).</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">11/14/2022 12:48:33</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">111420220833 reconnection</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Trying a new method for disconnect reconnect. I really want to eliminate any possible chatter when you are not actually using the app.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">11/10/2022 10:45:48</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Sending Seekers when Fully Ghosted</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">So @Gupy pointed out that a rare move would cause an issue. I fixed this issue this AM, should no longer be a problem. Thanks so much for pointing it out, I would not have generally given a fully ghosted opponent a Seeker, and Fossa typically would not either, so it wasn&#39;t really tested. It is now, thank you so much.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">11/4/2022 22:32:49</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">BOOM: 110420221928 - Captains and Hotel Cards</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Major change to opening flow, and now with Helpful manual.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">11/3/2022 16:40:39</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">FTUI for Rotate and Lock</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Seems some bounces off the Rotate and lock phase, I updated the FTUE and made the button read LOCK LOCK LOCK. Helpful?</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">11/2/2022 22:26:57</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Ditched the New / Resume on the main menu</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Resume is now in the Existing games menu, noobs were clicking on and not getting a great experience. The Play menu should suffice for now.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">11/1/2022 14:44:44</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fix Dock 1 clicking weirdness</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-380: Dock 1 was hard to click.</span>
                    </p><p className="c1"><span className="c2">FIX: End of game errored, I caused the end of game report even to push to /shop after closing.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">11/1/2022 13:23:09</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-378 Transcripts</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Apparently I disconnected the transcripts, and more importantly the toasts. Those are back and seem to be working properly again.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">11/1/2022 11:28:41</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">110120220827 - Fix play menus add Ranked Menu</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I updated the play menus to get rid of the weirdness of &quot;doing things in the wrong order&quot; and also false launches. Should all be working properly.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">Caused the Ranked Play menu to function as originally intended, no AI and at least 2 players.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/24/2022 16:00:16</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Profiles! Including &quot;Rank&quot;</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">All players now have visible profiles. Click your name, or the name of another player in your group to see. Player rank is now visible at all times in the group menu.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/22/2022 13:09:51</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">QOL Fixes, 3D fixes</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Top left buttons don&#39;t collide with the docks now.</span>
                    </p><p className="c1"><span className="c2">I pushed out our latest ship builds, everything is lined up and happy.</span>
                    </p><p className="c1"><span className="c2">More in the pipe!</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/21/2022 19:29:29</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">New Art!</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Updates to Lodging, Snohe Altar, and Ghost Zoo!!! Applied everywhere!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/21/2022 17:42:57</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Groups and LFG!</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Now with nice groups and looking for group!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/18/2022 14:20:27</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Gotta stop pushing so much stuff at once!</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">We are friends! It&#39;s super cool.</span></p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/17/2022 18:42:18</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Gotta stop pushing so much stuff at once!</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Today is so good.</span></p><p className="c1"><span className="c2">New Friends menu, new Groups menu, new Play menu, new Play submenu. New Animated Background!!!!</span>
                    </p><p className="c1"><span className="c2">All this and more, check it out, more coming very soon indeed.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span className="c2">10/12/2022 18:49:24</span>
                    </p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Big big push today.... Friends!</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">That&#39;s right! Now with a friends list, and the ability to request friends to join!</span>
                    </p><p className="c1"><span className="c2">It&#39;s live, so friends connecting and disconnecting should show status!</span>
                    </p><p className="c1"><span className="c2">Check it out and let us know what you think!</span></p>
                    </td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">10/9/2022 8:36:17</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Massive pushes</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Updates to most 2d and 3d resources. Pretty big update to the 3D game including waygates and traffic.</span>
                    </p><p className="c1"><span className="c2">Focus playtesting on the 3D assets please.</span></p><p
                        className="c1"><span className="c2">Enjoy!</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/27/2022 13:49:22</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Lots of Pushes 092720221042</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Now with Image Based Lighting, and a solar system.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/12/2022 14:46:45</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Pushes since yesterday</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Realigned the competitor boxes, switched to more precise hotel background information. Added dynamic libraries for ships, skins, and more. Transcript button is in a new place, we will see.... more coming.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/9/2022 19:45:10</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Push 090920221643</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">New Scorebars</span></p><p className="c1"><span
                        className="c2">Observer button</span></p><p className="c1"><span className="c2">competitor players layout</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/9/2022 12:47:23</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Multipush -- New User Registration and Password Resets</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Took our modules from experiencemaster.com and integrated them (with version upgrades) into HSH. Now with nice registration and password reset functionality. Decreased the enter username and password requirements (it remembers) and increased token timeout to 365 days.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/7/2022 16:09:47</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Massive GUI rebuild. Menus and URLs</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-248: Massive rebuild. Now using urls and parameters for routing. Still testing to make sure I didn&#39;t miss anything. Menus are expecting urls now instead, it&#39;s just better anyway. Resume now works as intended originally. Refresh stays. Lobby included in page at bottom. Player one note.</span>
                    </p><p className="c1 c8"><span className="c2"></span></p><p className="c1"><span className="c2">New background images too!!!!</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/4/2022 14:26:44</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Sunday Notification improvements... a few bug fixes</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Oh and give special guest was a bit quirky.... I think that should smooth it out.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/4/2022 14:26:04</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Sunday Notification improvements... a few bug fixes</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-245: More waiting notifications</span>
                    </p><p className="c1"><span className="c2">HSHA-243: Active player queryset improvement.</span></p>
                        <p className="c1"><span className="c2">Fixed the Not in lodging transcript.</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/4/2022 0:02:07</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Notifications Push</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Now you can have the system ping your phone and/or desktop when it is your turn.</span>
                    </p><p className="c1"><span className="c2">Took me a bit to get the firebase working nicely, but it all seems happy now.</span>
                    </p></td>
                </tr>
                <tr className="c15">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">9/1/2022 18:28:48</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Multistage push</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-226: Juice and Anti-Juice now allow change in robots mid action.</span>
                    </p><p className="c1"><span className="c2">HSHA-239: Clear discard X</span></p><p className="c1">
                        <span
                            className="c2">HSHA-214: End game report is now awesome, this includes new &quot;Tied&quot; state and stats for all number of players.</span>
                    </p><p className="c1"><span className="c2">Patched out the image mapper clear, should hopefully help clear it on mobile.</span>
                    </p><p className="c1"><span className="c2">Moved the river to the bottom of the game display.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/30/2022 22:43:47</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 083020221941 Experimenting with fullscreen fix and pools display</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-219: pools (placeholder). next plug them in. possible fullscreen fix. Player storage fix.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/30/2022 16:01:06</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 083020221257 Robots and Back to our original game layout finally!</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-220 Robots! Also improved the affordance on activated rooms. Massive rebuild of how the layout is. Very much like the board game. Cut the action hex so it looks tighter and is quicker to use.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/30/2022 12:15:37</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Push 083020220912</span></p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-224: Configuration menu with: Display Name, Volume, Fullscreen, FTUE toggle, Push Notifications.</span>
                    </p><p className="c1"><span className="c2">HSHA-225: Robot sales are now 2:1</span></p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/29/2022 12:20:37</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 082920220904: Events, Sounds, current player display and more</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">So events now show (sometimes) and many events now have sounds. Update the &quot;Sound Library&quot; with these updates and async loaders. Fairly big reimplementation.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/26/2022 17:57:27</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 082520221444 Fix discard, current message, add score bars and player names</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Nice QoL upgrades today.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/25/2022 16:57:15</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 082520221353 Quality of life upgrades</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-58: Reorder, ftue toggle, click for menus, clickability tweaks, context end button.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/23/2022 22:17:45</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 082320221916 - Last Choice</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Ok so all the choices are in now. So next up I&#39;ll reconnect the 3d verse and we can test them both for end-to-end efficacy. Pretty cool.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/23/2022 19:46:43</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 082320221644: Anti-Juice, Vanguard gives lodging</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">These should both work now (2d).</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/23/2022 18:35:46</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push 082320221532 Give special guest</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">These are all 2d updates, 3d obviously already works.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/23/2022 15:13:43</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push: 082320221208 Advertisement, Robot Activation, Score</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Advertisement highlighting and most functions. I now need to do the more edgy cases. Most major items done.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/22/2022 20:19:51</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Push: 082220221715 Mobile upgrades</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I&#39;ve disabled 3d for my own convenience. It will probably be back tomorrow.</span>
                    </p><p className="c1"><span className="c2">Check out the new scrolling river!!! Check out the new Lobby!</span>
                    </p><p className="c1"><span className="c2">Only advertise and activate rooms, remain outstanding. I expect to have both those back up and 3d back tomorrow.</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/16/2022 19:23:38</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Push: 081620221620</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">HSHA-198: New Log. Scrolling awesome. Fixed message display. Fixed mini-game clicking on the middle button. New FTUE experience for actions (no shutting it off yet).</span>
                    </p></td>
                </tr>
                <tr className="c0">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/16/2022 11:59:54</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Push: 081620220849</span></p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">PUSH: 081620220849: HSHA-198: Switch to GLTF format. Ships now land squarely. Fix regular guests always giving amenity.</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/15/2022 15:08:33</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">PUSH: 081520221206 HSHA-207 &amp; 191 Fixes during scoring for ghosts and amenity points</span>
                    </p></td>
                    <td className="c9" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Upgraded amenities were not pointing properly. Ghosts were still deducting from the wrong player when scoring (previously though fixed, now fixed and extra tested).</span>
                    </p></td>
                </tr>
                <tr className="c5">
                    <td className="c3" colSpan="1" rowSpan="1"><p className="c4"><span
                        className="c2">8/13/2022 12:56:27</span></p></td>
                    <td className="c7" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">PUSH: 081320220955 HSHA-200 Bug Stem Vanguard score causing loop (backend only)</span>
                    </p></td>
                    <td className="c6" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Didn&#39;t seem to be clearing the docks. It now does no matter what, added additional assertions to tests.</span>
                    </p></td>
                </tr>
            </table>
            <p className="c1 c21 c8"><span className="c19"></span></p>


            <BottomMenu/>
        </div>
    }
    }
